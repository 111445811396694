import { isEmpty, isEqual, isNil, join, orderBy } from 'lodash'
import {
    BLANK_HOLDER,
    DealerStatusTextConstant,
    DealerStatusTextShortConstant,
    DealerStatusValueConstant,
    EnvNameConstant,
    NO,
    ServerErrorMessage,
    SortConstant,
    YES
} from './constant'
import moment from 'moment'
import 'moment-timezone'
import {NotificationMessage} from '../component/common/notification-message/NotificationMessage'
import { getNextAvailableRuns } from './data-source/sale-datasource'
import HighlightText, {FormattedVinWithHighlightText} from '../component/common/highlight-text/HighlightText'
import {v4 as uuid} from "uuid";
import {t} from "i18next";

const currencyFormatter = require('currency-formatter')
const SLIDE_OUT_CLOSE_CLASS = 'slide-out-close'
const SVG_TAG_NAME  ='svg'

export function isProduction() {
    return isEqual(
        process.env.REACT_APP_ENVIRONMENT,
        EnvNameConstant.PRODUCTION_ENVIRONMENT
    )
}

export function getDealershipDisplayStatus(status) {
    if (
        status === DealerStatusValueConstant.UNAUTH ||
        status === DealerStatusValueConstant.OUT_OF_BUSINESS
    ) {
        return DealerStatusTextConstant.UNAUTH
    } else if (status === DealerStatusValueConstant.INACTIVE) {
        return DealerStatusTextConstant.INACTIVE
    } else {
        return ''
    }
}

export function getDealershipStatusClass(status) {
    if (
        status === DealerStatusValueConstant.UNAUTH ||
        status === DealerStatusValueConstant.OUT_OF_BUSINESS
    ) {
        return 'status-unauth'
    } else if (status === DealerStatusValueConstant.INACTIVE) {
        return 'status-inactive'
    } else {
        return 'status-active'
    }
}

export function getVehicleTitle(vehicle) {
    return join([vehicle.year, vehicle.make, vehicle.model, vehicle.trim], ' ')
}

export function getVehicleFormattedVin(vehicle) {
    if (vehicle.vin.length > 8) {
        const nonEm = vehicle.vin.slice(0, vehicle.vin.length - 8)
        return nonEm + '<b>' + getVehicle8lastVin(vehicle) + '</b>'
    }
    return '<b>' + vehicle.vin + '</b>'
}

export function getVehicleFormattedVinWithHighlightText(vehicle, highlight) {
    if (vehicle.vin.length > 8) {
        // There are some characters matched with the highlight text, so we replace it with highlighted texts
        if (vehicle.vin.toUpperCase().includes(highlight.toUpperCase())) {
            return (
                <FormattedVinWithHighlightText
                    text={vehicle.vin}
                    highlight={highlight}
                />
            )
        }

        // There is no characters matched with the highlight text, so we keep current formatting
        return <span dangerouslySetInnerHTML={{__html: getVehicleFormattedVin(vehicle)}}></span>

    }

    return (
        <b>{highlightText(vehicle.vin, highlight)}</b>
    )
}

function highlightText(text, highlight) {
    return <HighlightText text={text} highlight={highlight} />
}

export function getFormattedDealerInfo(dealer) {
    const status = getDealershipDisplayStatus(dealer.status)
    return `${dealer.id} - 
            ${dealer.name} -
            ${dealer.city}, 
            ${dealer.province} 
            ${!isEmpty(status) ? '(' + status + ')' : ''}`
}

export function getFormattedVehicleSellerInfo(vehicle) {
    return `${vehicle.sellerNumber} -
            ${vehicle.sellerName} -
            ${vehicle.sellerCity}, 
            ${vehicle.sellerProvince}`
}

export function getFormattedVehicleOdometer(vehicle) {
    if(isNil(vehicle.odometer) || vehicle.odometer === '') {
        return ' -- '
    }

    return currencyFormatter.format(vehicle.odometer, {
        symbol: '',
        precision: 0,
        format: '%v'
    })
}

export function getFormattedWo(vehicle) {
    if(isNil(vehicle.workOrderNumber) || vehicle.workOrderNumber === '') {
        return ' -- '
    }

    return vehicle.workOrderNumber
}

export function getFormattedVehicleAdjMmr(vehicle) {
    return currencyFormatter.format(vehicle.adjMmr, { code: 'USD', precision: 0 })
}

export function getVehicle8lastVin(vehicle) {
    return vehicle.vin.slice(-8)
}

export function displayString(string) {
    return !!string && string.trim() ? string : BLANK_HOLDER
}

export function convertDateFromAS400(date, format) {
    if (!!date) {
        const matches = date.match(/(\d{4})[^\d]?(\d{2})[^\d]?(\d{2})/)
        if (matches?.length) { //If the date is match regex, it will always return an array with 4 elements
            const dateFormatChar = format.match(/[\W]/).toString()
            const result = `${matches[2]}${dateFormatChar}${matches[3]}${dateFormatChar}${matches[1]}`
            //Re-check the format after converting
            if (moment(result, format, true).isValid()) {
                return result
            } else {
                console.error('Cannot convert AS400 date: ' + date)
            }
        }
    }

    return BLANK_HOLDER
}

export function formatSearchHistoryDate (date) {
    date = new Date(date);
    let dd = date.getDate();
    let mm = date.getMonth()+1;
    let yyyy = date.getFullYear();

    if(dd<10){
        dd = '0' + dd
    }
    if(mm<10){
        mm = '0' + mm
    }
    return mm + '/' + dd + '/' + yyyy;
}

export function getFormattedStatus(status) {
    return status === DealerStatusValueConstant.UNAUTH
        ? DealerStatusTextShortConstant.UNAUTH
        : status === DealerStatusValueConstant.OUT_OF_BUSINESS
        ? DealerStatusTextShortConstant.OUT_OF_BUSINESS
        : status === DealerStatusValueConstant.INACTIVE
        ? DealerStatusTextShortConstant.INACTIVE
        : DealerStatusTextShortConstant.ACTIVE
}

export function getFormattedRegisterString(register) {
    return register ? YES : NO
}

export function handleNotificationMessage(errorMessage, type = 'error')
{
    return <NotificationMessage type={type} isOpen={true}>
        {errorMessage}
    </NotificationMessage>
}

export function handleErrorException(ex) {
    let error = { status: "", message: "", isValidAuction: false }
    if (ex.code === 'ERR_NETWORK') {
        error.status = ex.code
        error.message = ex.message
    } else if (ex.response) {
        error.status = ex.response?.status
        switch (error.status) {
            case 500:
                error.message = ServerErrorMessage.INTERNAL_ERROR
                break
            default:
                error.message = ex.response.data.message || ex.response.data.error
        }
    }
    return error
}


/*
    Allow user only input number and some given other
*/
export function handleEventOnKeyDownForNumber(e, allowKeyCodes) {
    if (e.shiftKey) {
        if (
            (e.keyCode < 37 || e.keyCode > 40) // arrow key
        ) {
            // letter
            e.preventDefault()
        }
    } else if (e.ctrlKey) {
        if (
            (e.keyCode < 37 || e.keyCode > 40) && // arrow key
            e.keyCode !== 65 && // a or A to select all
            e.keyCode !== 67 && // c or C to copy
            e.keyCode !== 86 && // v or V to paste
            e.keyCode !== 88
        ) {
            // x or X to cut
            e.preventDefault()
        }
    } else {
        if (!isNil(allowKeyCodes) && allowKeyCodes.length > 0 && allowKeyCodes.includes(e.keyCode)) {
            return
        }
        
        if (
            e.keyCode !== 8 && // backspace
            e.keyCode !== 9 && // tab
            e.keyCode !== 13 && // enter
            (e.keyCode < 37 || e.keyCode > 40) && // arrow key
            e.keyCode !== 46 && // delete
            (e.keyCode < 48 || e.keyCode > 57) && // 0-9
            (e.keyCode < 96 || e.keyCode > 105) // num0-num9
        ) {
            // others
            e.preventDefault()
        }
    }
}

/*
    Allow user only input number and alphabet characters and some given other
*/
export function handleEventOnKeyDownForNumberAlphabet(e, allowKeyCodes) {
    if (e.shiftKey) {
        if (
            (e.keyCode < 37 || e.keyCode > 40) && // arrow key
            (e.keyCode < 65 || e.keyCode > 90) // alphabet
        ) {
            // letter
            e.preventDefault()
        }
    } else if (e.ctrlKey) {
        if (
            (e.keyCode < 37 || e.keyCode > 40) && // arrow key
            e.keyCode !== 65 && // a or A to select all
            e.keyCode !== 67 && // c or C to copy
            e.keyCode !== 86 && // v or V to paste
            e.keyCode !== 88
        ) {
            // x or X to cut
            e.preventDefault()
        }
    } else {
        if (!isNil(allowKeyCodes) && allowKeyCodes.length > 0 && allowKeyCodes.includes(e.keyCode)) {
            return
        }

        if (
            e.keyCode !== 8 && // backspace
            e.keyCode !== 9 && // tab
            e.keyCode !== 13 && // enter
            (e.keyCode < 37 || e.keyCode > 40) && // arrow key
            e.keyCode !== 46 && // delete
            (e.keyCode < 48 || e.keyCode > 57) && // 0-9
            (e.keyCode < 96 || e.keyCode > 105) && // num0-num9
            (e.keyCode < 65 || e.keyCode > 90) // alphabet
        ) {
            // others
            e.preventDefault()
        }
    }
}
export function handleValueInArray(arr, value) {
    var index = arr.indexOf(value);

    if (index !== -1) {
        arr.splice(index, 1);
    } else {
        arr.push(value)
    }

    return arr
}

export function isEnableToggleFlag(toggleConfigValue, auctionCode) {
    if (isNil(toggleConfigValue) || isNil(auctionCode)) {
        return false
    }

    if (toggleConfigValue.trim().toUpperCase() === 'ALL') {
        return true;
    }

    const auctionCodes = toggleConfigValue.split(',')
    const auctionConfig = auctionCodes.filter(p => p.trim().toUpperCase() === auctionCode.toUpperCase())
    if (!isNil(auctionConfig) && auctionConfig.length > 0) {
        return true
    }

    return false
}

export function getLastSixVin(vin) {
    return vin.slice(-6)
}

Array.prototype.removeIf = function(callback) {
    let i = this.length
    while (i--) {
        if (callback(this[i], i)) {
            this.splice(i, 1)
        }
    }
}

Array.prototype.removeAnElementIf = function(callback) {
    let i = this.length
    while (i--) {
        if (callback(this[i], i)) {
            this.splice(i, 1)
            return
        }
    }
}

export function getNextAvailableRunData(auctionCode, saleYear, saleNumber, setNextAvailableRuns, setNextAvailableErrorMessage, setLoadNextAvailable, t){
    getNextAvailableRuns(auctionCode, saleYear, saleNumber)
                .then((response) => {
                    const laneNumbers = response.data
                    setNextAvailableRuns(laneNumbers)
                    if (!isNil(laneNumbers)
                        && !isEmpty(laneNumbers)) {
                            setNextAvailableErrorMessage(null)
                        }else {
                            setNextAvailableErrorMessage(t('MESSAGE.COMMON.NEXT_AVAILABLE_ERROR_MESSAGE', { auctionCode: auctionCode, saleNumber: saleNumber })
                            )
                        }
                })
                .catch(() => {
                    if(isEmpty(auctionCode)){
                        setNextAvailableErrorMessage(t('MESSAGE.COMMON.AUCTION_EMPTY'))
                    }else{
                        setNextAvailableErrorMessage(t('MESSAGE.COMMON.NEXT_AVAILABLE_ERROR_MESSAGE', { auctionCode: auctionCode, saleNumber: saleNumber })
                    )
                    }
                })
                .finally(() => {
                    setLoadNextAvailable({
                        loading: false,
                        loaded: true
                    })
                })
}

export function convertDateStringWithTimeZone(date, timeZone, format) {
    return !!date ? moment(date).tz(timeZone).format(format) : BLANK_HOLDER
}

export function getFormatLastSaved(savedLane, format) {
    const lastUpdated = isNil(savedLane?.updatedAt) ? savedLane?.createdAt : savedLane?.updatedAt
    return "Last saved " + convertDateStringWithTimeZone(lastUpdated, 'US/Eastern', 'MM/DD/YYYY [at] hh:mmA') + " by " + savedLane?.userName;
}

export function randomInteger() {
    return Math.floor(Math.random() * 1000000000)
}

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export function getMaxInteger() {
    return Math.pow(2,31) - 1
}

export function calculatedSalesPercentage(stats) {
    if (!stats) {
        return 'NA'
    }
    var auction_code;
    for (var auc in stats) { auction_code = auc; }
    var salesPercentage = (stats[auction_code].total_sold / stats[auction_code].total_registered) * 100;
    salesPercentage = Math.round(salesPercentage * 100) / 100;
    return isNaN(salesPercentage) ? 'NA' : salesPercentage
}

export function buildFileNameExportRequestHistory() {
    return `Request_History_Results_${convertDateStringWithTimeZone((new Date()), "UTC", "MMDDYY_hh_mma")}`;
}

export function sortVehicleByCustomCriteria(items, customCriteria) {
    let criteriaFunctions = customCriteria.map(criterion => {
        if(criterion.order === SortConstant.ASCENDING) {
            if (criterion.value === SortConstant.LAST_SIX_VIN) {
                return (item) => getLastSixVin(item.vin) || undefined;
            } else {
                return (item) => item[criterion.value] !== 0 ? (item[criterion.value] || undefined) : 0;
            }
        } else {
            if (criterion.value === SortConstant.LAST_SIX_VIN) {
                return (item) => getLastSixVin(item.vin);
            } else {
                return (item) => item[criterion.value];
            }
        }

    });
    let orders = customCriteria.map(c => c.order);

    if(!isNil(customCriteria) && !isEmpty(customCriteria)) {
        criteriaFunctions.push("workOrderNumber")
        orders.push("asc")
    }

    return orderBy(items, criteriaFunctions, orders);
}

export function buildDropdownOptionsData(data) {
    const defaultDropdownOption = {
        name: t('LABEL.SALE_PICKER.SELECT'),
        value: null,
        disable: false,
        key: uuid()
    }
    let optionsData = [defaultDropdownOption]
    if (!isNil(data) && !isEmpty(data)) {
        data.forEach((item) => {
            let optionData = {
                name: item,
                value: item,
                disable: false,
                key: uuid()
            }
            optionsData.push(optionData)
        })
    }
    return optionsData
}

/**
 * Open work bench snow modal dialog
 * @returns {function(): *}
 */
export function openSnowModal() {
    removeSvgFromSlideOut()
    window.EmployeeWorkbench.openSnowModal()
}

function removeSvgFromSlideOut() {
    const slideOutCloseButton = document.getElementsByClassName(SLIDE_OUT_CLOSE_CLASS)
    if (slideOutCloseButton) {
        const closeIcon = document.createElement('i')
        closeIcon.classList.add('icon')
        closeIcon.classList.add('prism-icon-cross')
        Array.from(slideOutCloseButton).forEach(button => {
            removeSvg(button)
            button.appendChild(closeIcon)
        })
    }
}

export function removeSvg(element) {
    if (element) {
        const svgList = element.getElementsByTagName(SVG_TAG_NAME);
        if (svgList) {
            Array.from(svgList).forEach(svg => svg.remove())
        }
    }
}
